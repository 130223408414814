<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title mb-0">
        <b-icon class="mr-2" icon="person-plus-fill" />
        {{ $t("createUser.title") }}
      </h1>
      <b-button class="mr-2" variant="primary" pill @click="$router.go(-1)">
        <b-icon-arrow90deg-left class="mr-2" />
        {{ $t("back") }}
      </b-button>
    </div>
    <b-overlay :show="loading" rounded="sm">
      <b-card border-variant="light" class="shadow-sm card-rounded mt-4">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(create)">
            <b-form-group
              id="group-name"
              :label="$t('firstName')"
              label-for="firstName"
            >
              <b-form-input
                id="firstName"
                v-model="form.firstName"
                :placeholder="$t('firstNamePlaceholder')"
                data-cy="new-user-firstname"
              />
            </b-form-group>

            <b-form-group
              id="group-name"
              :label="$t('lastName')"
              label-for="lastName"
            >
              <b-form-input
                id="lastName"
                v-model="form.lastName"
                :placeholder="$t('lastNamePlaceholder')"
                data-cy="new-user-lastname"
              />
            </b-form-group>

            <ValidationProvider
              v-slot="{ errors, valid }"
              name="E-mail Address"
              rules="required|email"
            >
              <b-form-group id="group-email" label-for="e-mail">
                <template v-slot:label>
                  {{ $t("email") }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="email"
                  v-model="form.email"
                  :placeholder="$t('emailPlaceholder')"
                  :state="getValidationState(errors, valid)"
                  data-cy="new-user-email"
                >
                </b-form-input>
                <b-form-invalid-feedback id="live-feedback-email">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, valid }"
              name="Username"
              rules="required|validUsername"
            >
              <b-form-group id="group-username" label-for="username">
                <template v-slot:label>
                  {{ $t("createUser.username") }}
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="username"
                  v-model="form.username"
                  :placeholder="$t('createUser.usernamePlaceholder')"
                  :state="getValidationState(errors, valid)"
                  data-cy="new-user-username"
                >
                </b-form-input>
                <b-form-invalid-feedback id="live-feedback-username">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <b-form-group :label="$t('roles')" label-for="tags-with-dropdown">
              <b-form-tags
                id="tags-with-dropdown"
                v-model="value"
                class="mb-2"
                no-outer-focus
                size="lg"
                tag-pills
              >
                <template v-slot="{ tags, disabled, addTag, removeTag }">
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mb-2"
                  >
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        :disabled="disableTag(tag)"
                        :title="toText(tag)"
                        variant="success"
                        @remove="onRemoveClick(tag, removeTag)"
                        >{{ toText(tag) }}
                      </b-form-tag>
                    </li>
                  </ul>

                  <b-dropdown
                    block
                    data-cy="new-user-role-dropdown"
                    menu-class="w-100"
                    size="sm"
                    variant="outline-secondary"
                  >
                    <template #button-content>
                      <b-icon icon="tag-fill"></b-icon>
                      {{ $t("chooseRoles") }}
                    </template>
                    <b-dropdown-form @submit.stop.prevent="() => {}">
                      <b-form-group
                        :description="searchDesc"
                        :disabled="disabled"
                        :label="$t('searchRoles')"
                        class="mb-0"
                        label-cols-md="auto"
                        label-for="tag-search-input"
                        label-size="sm"
                      >
                        <b-form-input
                          id="tag-search-input"
                          v-model="search"
                          autocomplete="off"
                          size="sm"
                          type="search"
                        />
                      </b-form-group>
                    </b-dropdown-form>

                    <b-dropdown-divider />

                    <b-dropdown-group id="dropdown-group-1">
                      <b-dropdown-item-button
                        v-for="option in availableOptions"
                        :key="option.id"
                        data-cy="new-user-role-dropdown-item"
                        @click="onOptionClick({ option, addTag })"
                      >
                        {{ option.text }}
                      </b-dropdown-item-button>
                    </b-dropdown-group>
                    <b-dropdown-text v-if="availableOptions.length === 0">
                      {{ $t("rolesEmpty") }}
                    </b-dropdown-text>
                  </b-dropdown>
                </template>
              </b-form-tags>
            </b-form-group>

            <div class="d-flex justify-content-end">
              <b-button
                variant="primary"
                data-cy="new-user-submit-button"
                pill
                type="submit"
              >
                {{ $t("createUser.button") }}
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { email, required } from "vee-validate/dist/rules";

extend("email", email);
extend("required", {
  ...required,
  message: "{_field_} is required",
});

extend("validUsername", (value) => {
  if (!value.toString().match("^[a-z][a-z0-9_.]{1,}$")) {
    return "The username must start with a small letter (a-z) and must only contain symbols: a-z, 0-9, _ and . with a minimal length of 4 characters.";
  }
  return true;
});

export default {
  name: "CreateUser",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
      },
      roles: [],
      search: "",
      value: [],
      options: [],
      loading: false,
      organization: this.$route.params.id,
    };
  },
  async mounted() {
    try {
      // get roles
      const {
        data: { roles },
      } = await this.$http.get(
        `${this.$cfg.BASE_IDENTITY_VERSION_URL}/tenants/${this.organization}/roles`
      );

      roles.forEach((el) => {
        this.options.push({ value: el.id, text: el.name });
      });
    } catch (err) {
      if (err.response) console.log(err.response);
    }
  },
  methods: {
    async create() {
      this.loading = true;
      try {
        const { data } = await this.$http.post(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/tenants/${this.organization}/accounts`,
          {
            email: this.form.email,
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            name: this.form.username,
          },
          {
            errors: {
              409: () => true,
              400: () => true,
            },
          }
        );

        await this.$http.put(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/accounts/${data.id}/roles`,
          {
            roleIds: [...this.value.map((el) => JSON.parse(el).value)],
          }
        );

        this.$root.$bvToast.toast("User created!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
        this.$router.push({ path: `/organization/${this.organization}` });
      } catch ({ response }) {
        if (response.status === 409) {
          switch (response.data.errorCode) {
            case 0:
              this.$refs.observer.setErrors({
                Username: [response.data.message],
              });
              break;
            case 1:
              this.$refs.observer.setErrors({
                ["E-mail Address"]: [response.data.message],
              });
              break;
          }
        } else if (response.status === 400) {
          this.$refs.observer.setErrors({
            ["E-mail Address"]: [response.data.message],
          });
        } else if (response) console.log(response);
      } finally {
        this.loading = false;
      }
    },

    getValidationState(errors, valid) {
      return errors[0] ? false : valid ? true : null;
    },

    // add tag on click
    async onOptionClick({ option, addTag }) {
      try {
        addTag(JSON.stringify(option));
        this.search = "";
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    // remove tag
    async onRemoveClick(tag, removeTag) {
      try {
        removeTag(tag);
        this.search = "";
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    // get display text for tag
    toText(tag) {
      return JSON.parse(tag).text;
    },

    // disable tag removal
    disableTag(tag) {
      let disable = true;
      this.options.forEach((el) => {
        if (JSON.stringify(el) === tag) {
          disable = false;
        }
      });
      return disable;
    },
  },
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.options.filter(
        (opt) => this.value.indexOf(JSON.stringify(opt)) === -1
      );

      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.text.toLowerCase().indexOf(criteria) > -1
        );
      }
      // Show all options available
      return options;
    },

    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return this.$i18n.t("searchRolesEmpty");
      }
      return "";
    },

    mappedTags() {
      return this.value.map((item) => JSON.parse(item).text);
    },
  },
};
</script>

<style scoped>
#dropdown-group-1 {
  max-height: 350px;
  overflow-y: auto;
}
</style>
