var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex mt-4 align-items-center justify-content-between"},[_c('h1',{staticClass:"page-title mb-0"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"person-plus-fill"}}),_vm._v(" "+_vm._s(_vm.$t("createUser.title"))+" ")],1),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","pill":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-icon-arrow90deg-left',{staticClass:"mr-2"}),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1)],1),_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-card',{staticClass:"shadow-sm card-rounded mt-4",attrs:{"border-variant":"light"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.create)}}},[_c('b-form-group',{attrs:{"id":"group-name","label":_vm.$t('firstName'),"label-for":"firstName"}},[_c('b-form-input',{attrs:{"id":"firstName","placeholder":_vm.$t('firstNamePlaceholder')},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})],1),_c('b-form-group',{attrs:{"id":"group-name","label":_vm.$t('lastName'),"label-for":"lastName"}},[_c('b-form-input',{attrs:{"id":"lastName","placeholder":_vm.$t('lastNamePlaceholder')},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})],1),_c('ValidationProvider',{attrs:{"name":"E-mail Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"id":"group-email","label-for":"e-mail"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("email"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"email","placeholder":_vm.$t('emailPlaceholder'),"state":_vm.getValidationState(errors, valid)},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"live-feedback-email"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Username","rules":"required|validUsername"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{attrs:{"id":"group-username","label-for":"username"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("createUser.username"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"username","placeholder":_vm.$t('createUser.usernamePlaceholder'),"state":_vm.getValidationState(errors, valid)},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('b-form-invalid-feedback',{attrs:{"id":"live-feedback-username"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":_vm.$t('roles'),"label-for":"tags-with-dropdown"}},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"id":"tags-with-dropdown","no-outer-focus":"","size":"lg","tag-pills":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var disabled = ref.disabled;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-2"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"disabled":_vm.disableTag(tag),"title":_vm.toText(tag),"variant":"success"},on:{"remove":function($event){return _vm.onRemoveClick(tag, removeTag)}}},[_vm._v(_vm._s(_vm.toText(tag))+" ")])],1)}),0):_vm._e(),_c('b-dropdown',{attrs:{"block":"","menu-class":"w-100","size":"sm","variant":"outline-secondary"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"icon":"tag-fill"}}),_vm._v(" "+_vm._s(_vm.$t("chooseRoles"))+" ")]},proxy:true}],null,true)},[_c('b-dropdown-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return (function () {}).apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"description":_vm.searchDesc,"disabled":disabled,"label":_vm.$t('searchRoles'),"label-cols-md":"auto","label-for":"tag-search-input","label-size":"sm"}},[_c('b-form-input',{attrs:{"id":"tag-search-input","autocomplete":"off","size":"sm","type":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('b-dropdown-divider'),_c('b-dropdown-group',{attrs:{"id":"dropdown-group-1"}},_vm._l((_vm.availableOptions),function(option){return _c('b-dropdown-item-button',{key:option.id,on:{"click":function($event){return _vm.onOptionClick({ option: option, addTag: addTag })}}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),(_vm.availableOptions.length === 0)?_c('b-dropdown-text',[_vm._v(" "+_vm._s(_vm.$t("rolesEmpty"))+" ")]):_vm._e()],1)]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary","pill":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("createUser.button"))+" ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }